export const recipeConsts = {
    REQUEST_RECIPE_DATA_PENDING: 'REQUEST_RECIPE_DATA_PENDING',
    REQUEST_RECIPE_DATA_SUCCESS: 'REQUEST_RECIPE_DATA_SUCCESS',
    REQUEST_RECIPE_DATA_BY_ID_PENDING: 'REQUEST_RECIPE_DATA_BY_ID_PENDING',
    REQUEST_RECIPE_DATA_BY_ID_SUCCESS: 'REQUEST_RECIPE_DATA_BY_ID_SUCCESS',
    REQUEST_RECIPE_DATA_BY_USER_ID_PENDING: 'REQUEST_RECIPE_DATA_BY_USER_ID_PENDING',
    REQUEST_RECIPE_DATA_BY_USER_ID_SUCCESS: 'REQUEST_RECIPE_DATA_BY_USER_ID_SUCCESS',
    REQUEST_FAVORITE_RECIPE_DATA_BY_USER_ID_PENDING: 'REQUEST_FAVORITE_RECIPE_DATA_BY_USER_ID_PENDING',
    REQUEST_FAVORITE_RECIPE_DATA_BY_USER_ID_SUCCESS: 'REQUEST_FAVORITE_RECIPE_DATA_BY_USER_ID_SUCCESS',
    REQUEST_RECENT_RECIPE_DATA_PENDING: 'REQUEST_RECENT_RECIPE_DATA_PENDING',
    REQUEST_RECENT_RECIPE_DATA_SUCCESS: 'REQUEST_RECENT_RECIPE_DATA_SUCCESS',
    REQUEST_RECIPE_DATA_BY_AUTHOR: 'REQUEST_RECIPE_DATA_BY_AUTHOR',
    ADD_RECIPE_DATA_PENDING: 'ADD_RECIPE_DATA_PENDING',
    ADD_RECIPE_DATA_SUCCESS: 'ADD_RECIPE_DATA_SUCCESS',
    UPDATE_STORED_RECIPE_DATA_PENDING: 'UPDATE_STORED_RECIPE_DATA_PENDING',
    UPDATE_STORED_RECIPE_DATA_SUCCESS: 'UPDATE_STORED_RECIPE_DATA_SUCCESS',
    REMOVE_RECIPE_DATA_PENDING: 'REMOVE_RECIPE_DATA_PENDING',
    REMOVE_RECIPE_DATA_SUCCESS: 'REMOVE_RECIPE_DATA_SUCCESS',
    UPDATE_ACTIVE_RECIPE_DATA: 'UPDATE_ACTIVE_RECIPE_DATA',
    ADD_FAVORITE_RECIPE_DATA_PENDING: 'ADD_FAVORITE_RECIPE_DATA_PENDING',
    ADD_FAVORITE_RECIPE_DATA_SUCCESS: 'ADD_FAVORITE_RECIPE_DATA_SUCCESS',
    REMOVE_FAVORITE_RECIPE_DATA_PENDING: 'REMOVE_FAVORITE_RECIPE_DATA_PENDING',
    REMOVE_FAVORITE_RECIPE_DATA_SUCCESS: 'REMOVE_FAVORITE_RECIPE_DATA_SUCCESS',
    ADD_INGREDIENT_DATA: 'ADD_INGREDIENT_DATA',
    REMOVE_INGREDIENT_DATA: 'REMOVE_INGREDIENT_DATA',
    CLEAR_RECIPE_DATA: 'CLEAR_RECIPE_DATA',
    GET_SEARCH_RESULTS_PENDING: 'GET_SEARCH_RESULTS_PENDING',
    GET_SEARCH_RESULTS_SUCCESS: 'GET_SEARCH_RESULTS_SUCCESS',
};